import axios from "axios";
import Swal from "sweetalert2";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL+'/api/v1/',
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  withCredentials: true,
  withXSRFToken: true,
});
axiosClient.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosClient.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response.data;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    console.log('...........ERR..............');
    console.log(error)
    console.log(error.response.data)
    console.log(error.response.data.message)
    if (error.response.data && error.response.data.message) {
      console.log('...........MESSAGE..............');
      console.log(error.response.data.message);
      console.log('.......ENDERR...............')
      switch (error.response.data.message) {
        case "Unauthenticated.":
          localStorage.setItem("user", null);
          localStorage.setItem("permissions", null);
          Swal.fire({
            text: "Vui lòng đăng nhập để tiếp tục",
            confirmButtonText: "Đăng nhập",
            backdrop: false,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              window.location.replace("/auth/login");
            }
          });
          break;

        default:
          Swal.fire({
            text: error.response.data.message,
          });
          break;
      }
    }

    return Promise.reject(error);
  }
);
export default axiosClient;
