import { cilLockLocked, cilUser } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CImage,
  CInputGroup,
  CInputGroupText,
  CNavLink,
  CRow,
} from "@coreui/react";
import React from "react";
import { useForm } from "react-hook-form";
import { Link} from "react-router-dom";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth } from "../context/AuthContext";
export default function Login() {
  document.title = "Đăng nhập"
  const auth = useAuth()
  
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {
      id: "",
      password: "",
    },
    resolver: validator,
  });
  const login = (data) => {
    auth.login(data)
    return;
  };
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <h1>Đăng nhập</h1>
                  <p className="text-medium-emphasis"></p>
                  <CForm onSubmit={handleSubmit(login)}>
                    <CInputGroup className="mb-4">
                      <CInputGroupText id="addon-wrapping">
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput
                        placeholder="Tên tài khoản"
                        name="id"
                        {...register("id")}
                      />
                      <div
                        className="error invalid-feedback"
                        style={{ display: "flex" }}
                      >
                        {errors.id?.message}
                      </div>
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupText id="addon-wrapping">
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        placeholder="Mật khẩu"
                        name="password"
                        {...register("password")}
                      />
                      <div
                        className="error invalid-feedback"
                        style={{ display: "flex" }}
                      >
                        {errors.password?.message}
                      </div>
                    </CInputGroup>
                    <CRow>
                      <CCol md={6}>
                        <CButton
                          className="px-4"
                          type="submit"
                        >
                          Đăng nhập
                        </CButton>
                      </CCol>
                      <CCol md={6}>
                        <CNavLink
                          className="px-0"
                          href="#"
                        >
                          Quên mật khẩu?
                        </CNavLink>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
              
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
}

const validator = yupResolver(
  Yup.object({
    id: Yup.string().required("Vui lòng nhập ID hoặc Email"),
    password: Yup.string()
      .required("Vui lòng nhập mật khẩu")
      .min(6, "Mật khẩu cần trên 6 ký tự"),
  })
);

