import React from 'react'
import { Route, Routes } from 'react-router-dom'
import DashboardLayout from '../../components/Layout/DashboardLayout'
import AuthMiddleware from '../../components/MiddleRoutes/AuthMiddleware'
import Dashboard from './Dashboard'

export default function DashboardFeature() {
  document.title = "Chào mừng đến với FleonERP"
  return (
    <Routes>
      <Route Component={AuthMiddleware}>
        <Route Component={DashboardLayout}>
          <Route path='/' Component={Dashboard}/>
        </Route>
      </Route>
    </Routes>
      
  )
}
