import axiosClient from "../../../api/axiosClient";

const profileApi = {
    get() {
        return axiosClient.get('user/profile');
    },
    update(payload){
        return axiosClient.post('user/profile', payload);
    }
}

export default profileApi;