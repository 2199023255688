import React from 'react'
import { useForm } from 'react-hook-form';
import { createModuleValidator } from '../Validation/createModuleValidator';
import { CButton, CForm, CFormCheck, CFormInput, CInputGroup, CInputGroupText, CRow } from '@coreui/react';
import {yupResolver} from '@hookform/resolvers/yup'
import Swal from 'sweetalert2';
function ModuleForm({submit}) {
    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm({
        mode: "onBlur",
        reValidateMode: "onChange",
        defaultValues: {
          moduleName: '',
          models: '',
          controller : true,
          migration: true,
          request: true,
          seed: true
        },
        resolver: yupResolver(createModuleValidator),
      });
      const onSubmit    =  async (data)  =>{
        try {
            let response = await submit(data)
            Swal.fire({text: response.message})
        } catch (error) {
            
        }

      }
    return (
        <CForm onSubmit={handleSubmit(onSubmit)}>
            <CInputGroup className="mb-4">
                <CInputGroupText id="addon-wrapping">
                Tên module
                </CInputGroupText>
                <CFormInput
                placeholder="Tên module bạn muốn tạo"
                name="moduleName"
                {...register("moduleName")}
                />
                <div
                className="error invalid-feedback"
                style={{ display: "flex" }}
                >
                {errors.moduleName?.message}
                </div>
            </CInputGroup>
            <CInputGroup className="mb-4">
                <CInputGroupText id="addon-wrapping">
                 Models
                </CInputGroupText>
                <CFormInput
                placeholder="Các model muốn tạo, cách nhau bằng dấu phẩy (,)"
                name="models"
                {...register("models")}
                />
                <div
                className="error invalid-feedback"
                style={{ display: "flex" }}
                >
                {errors.models?.message}
                </div>
            </CInputGroup>
            <CRow className='ms-4'>
                <CFormCheck className='pb-2' label="Tạo controller tương ứng" {...register('controller')}/>
                <CFormCheck label="Tạo migration tương ứng" {...register('migration')}/>
                <CFormCheck label="Tạo seed tương ứng" {...register('seed')}/>
                <CFormCheck label="Tạo request tương ứng" {...register('request')}/>
            </CRow>
            <CRow className='pt-2'>
                <CButton type='submit' className="px-4">Tạo</CButton>
            </CRow>
        </CForm>
    )
}

export default ModuleForm
