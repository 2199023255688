import { CButton, CFormInput, CInputGroup, CInputGroupText } from '@coreui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
function Config({ setRate, party , rate}) {
    console.log(rate)
    const {
        register,
        handleSubmit,
        reset,
        formState: { isSubmitting },
    } = useForm({
        mode: "onBlur",
        reValidateMode: "onChange",
        defaultValues: {
            rate: rate
        },
        resolver: yupResolver(validator),
    })
    const submit = (values) => {
        setRate(party, values.rate/100)
        
    }
    useEffect(()=>{
        reset({
            rate: (Number(rate)*100).toFixed(0)
        })
    }, [reset,rate])
    return (
        <CInputGroup className="mb-3">
            <CInputGroupText>0.</CInputGroupText>
            <CFormInput placeholder={`${party} rate`} defaultValue={rate} {...register('rate')} />
            <CButton type="button" color="success" variant="outline" onClick={handleSubmit(submit)}>Lưu</CButton>
        </CInputGroup>
    )
}

export default Config

const validator = Yup.object({
    rate: Yup.number().max(100).min(0)
})