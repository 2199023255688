import React, { useState } from 'react'
import useModule from '../hooks/useModule'
import Overlay from '../../../components/Overlay';
import { CButton, CCard, CCardBody, CCardHeader, CDropdown, CDropdownDivider, CDropdownItem, CDropdownMenu, CDropdownToggle, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilPlus } from '@coreui/icons';
import { NavLink } from 'react-router-dom';
import CreateModal from '../components/modals/createModal';
import Swal from 'sweetalert2';
import moduleApi from '../api/moduleApi';

function ModuleList() {
    const {data, isLoading, isError} = useModule();
    const [visible, setVisible] =   useState(false);
    const [createModal, setCreateModal] =   useState("Model");
    const [currentModule, setCurrentModule]   =   useState(null);
    const openModal = (module, modalName) => {
        setCreateModal(modalName)
        setCurrentModule(module)
        setVisible(true)
    }
    const runElement = (module,element) => {
      Swal.fire({
        text: `Xác nhận muốn chạy ${element} cho module ${module}`,
        showDenyButton: true,
        confirmButtonText: "Xác nhận",
        denyButtonText: `Huỷ bỏ`
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          moduleApi.runElement({module,element})
          Swal.fire({text: "Lệnh đã được chạy thành công!"})
        } else if (result.isDenied) {
          Swal.close()
        }
      });
    }
    const elements = ["Model","Controller","Migration", "Request","Seeder"]
    if(isLoading)
    return <Overlay show={true} />
    if(isError)
    return <>Gặp lỗi khi tải dữ liệu, xin vui lòng thử lại</>
    return (
        <CCard>
            <CCardHeader>Danh sách modules
            <NavLink to="/module/create"><CButton size='sm' className='float-end'><CIcon icon={cilPlus} /> Tạo module</CButton></NavLink>
            </CCardHeader>
            <CCardBody>
            <div>
                <CTable>
                    <CTableHead>
                        <CTableRow>
                            <CTableHeaderCell>Tên Module</CTableHeaderCell>
                            <CTableHeaderCell>Alias</CTableHeaderCell>
                            <CTableHeaderCell>Description</CTableHeaderCell>
                            <CTableHeaderCell>Priority</CTableHeaderCell>
                            <CTableHeaderCell>Quản lý</CTableHeaderCell>
                        </CTableRow>
                    </CTableHead>
                    <CTableBody>
                        {data?Object.keys(data).map((key)=>{
                            console.log(data[key])
                            return (
                              <CTableRow key={key}>
                                <CTableDataCell>{key}</CTableDataCell>
                                <CTableDataCell>
                                  {data[key].alias}
                                </CTableDataCell>
                                <CTableDataCell>
                                  {data[key].description}
                                </CTableDataCell>
                                <CTableDataCell>
                                  {data[key].priority}
                                </CTableDataCell>
                                <CTableDataCell>
                                  <CDropdown
                                    variant="btn-group"
                                  >
                                    <CDropdownToggle color="primary" size='sm'>
                                      Thao tác
                                    </CDropdownToggle>
                                    <CDropdownMenu>
                                      <CDropdownItem href={`/menu/${key}`}>
                                        Quản lý Menu
                                      </CDropdownItem>
                                    <CDropdownDivider/>
                                    {
                                    elements.map((value, k)=>{
                                        return (
                                        <CDropdownItem key={k} href="#" onClick={()=>openModal(key, value)}>
                                            Thêm {value}
                                        </CDropdownItem>
                                        )
                                        
                                    })
                                    }
                                      <CDropdownDivider/>
                                      <CDropdownItem href="#" onClick={()=>runElement(key, "Migration")}>
                                        Chạy migration
                                      </CDropdownItem>
                                      <CDropdownItem href="#" onClick={()=>runElement(key, "Seed")}>
                                        Chạy seed
                                      </CDropdownItem>
                                    </CDropdownMenu>
                                  </CDropdown>
                                </CTableDataCell>
                              </CTableRow>
                            );
                        }):'Bạn chưa tạo module nào'}
                    </CTableBody>
                </CTable>
            </div>
            </CCardBody>
            <CreateModal action={createModal} show={visible} setvisible={setVisible} module={currentModule} />
        </CCard>
    )
}

export default ModuleList
