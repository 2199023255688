import { CButton, CCard, CCardBody, CCardHeader, CCol, CListGroup, CListGroupItem, CRow, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import Config from '../components/Config'
import useHallDetails from '../hooks/useHallDetails'
import hallApi from '../api/hallApi'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
function FightIndex() {
    const params = useParams()
    const { data, isLoading } = useHallDetails(params.id)
    const [videoSource, setVideoSource] = useState('')
    const [liveStatus, setLiveStatus] = useState(false)
    const [rateWala, setRateWala] = useState(0)
    const [rateMeron, setRateMeron] = useState(0)
    const [betStatus, setBetStatus] = useState(0)
    const [fightId, setFightId] = useState(null)
    useEffect(() => {
        if (data) {
            setVideoSource(data.hall.videoSource)
            setLiveStatus(data.hall.live === 1)
            setRateMeron(data.rateMeron)
            setRateWala(data.rateWala)
            setBetStatus(data.status)
            setFightId(data.currentFight)
            console.log(data)
        }
    }, [data, isLoading])
    const startEvent = async () => {
        try {
            await hallApi.startEvent(params.id)
            toggleEvent()
        } catch (error) {

        }
    }
    const endEvent = async () => {
        try {
            await hallApi.endEvent(params.id)
            toggleEvent()
        } catch (error) {

        }
    }
    const startBet = async () => {
        try {
            const fight = await hallApi.startBet(params.id)
            setBetStatus(1)
            setFightId(fight.id)
        } catch (error) {

        }
    }
    const endBet = async () => {
        try {
            await hallApi.endBet(fightId)
            setBetStatus(2)
        } catch (error) {

        }
    }
    const cancelBet = async () => {
        try {
            await hallApi.cancelBet(fightId)
            setBetStatus(0)
        } catch (error) {

        }
    }
    const setWinner = async (party) => {
        try {
            await hallApi.setWinner(fightId, party)
            setBetStatus(0)
        } catch (error) {

        }
    }
    const toggleEvent = () => {
        setLiveStatus(!liveStatus)
    }
    const setRate = async (party, value) => {
        try {
            await hallApi.setRate(fightId, { party, value })
            switch (party) {
                case 'wala':
                    setRateWala(value)
                    break;
                case 'meron':
                    setRateMeron(value)
                    break;
                default:
                    break;
            }
        } catch (error) {

        }
    }
    window.Echo = new Echo({
        broadcaster: 'pusher',
        key: '90dc6c9608eee3dd9501',
        cluster: 'ap1',
        forceTLS: true
    })
    var channel = window.Echo.channel('game-state');
    channel.listen('.change', function (data) {
        alert(JSON.stringify(data));
    });
    return (
        <>
            <CRow>
                <CCol md={5}>
                    <CCard>
                        <CCardBody>
                            <iframe width="100%" height="400" src={videoSource} allowFullScreen="" allow="autoplay" title='Video'></iframe>
                            <CCol md={12}>
                                {liveStatus == 0 ?
                                    <CButton color='primary' style={{ width: '100%' }} onClick={() => startEvent()} >Open Event</CButton>
                                    : <CButton color='light' style={{ width: '100%' }} onClick={() => endEvent()}>End Event</CButton>}
                            </CCol>
                        </CCardBody>
                    </CCard>
                </CCol>
                <CCol md={7}>
                    <CCard>
                        <CCardBody>
                            <CRow>
                                <CCol md={12}>
                                    Status: {betStatus == 1 ? "Open" : "Close"}
                                </CCol>
                            </CRow>
                            {
                                liveStatus == 1 ? <CRow>
                                    <CCol md={6}>
                                        {betStatus == 1 ?
                                            <CButton style={{ width: '100%' }} onClick={() => { endBet() }}>End Bet</CButton>
                                            : <CButton style={{ width: '100%' }} disabled={betStatus != 0} onClick={() => { startBet() }}>Start Bet</CButton>}
                                    </CCol>
                                    <CCol md={6}>
                                        <CButton style={{ width: '100%' }} disabled={betStatus != 1} color="dark" onClick={() => cancelBet()}>Cancel Game</CButton>
                                    </CCol>
                                </CRow> : ""
                            }

                            <CRow className='mt-4'>

                            </CRow>
                        </CCardBody>
                    </CCard>
                    <CCard className='mt-4'>
                        <CCardBody>
                            <CRow>
                                <CCol md={4}>
                                    <CCard color='danger' className='mb-4'>
                                        <CCardHeader className='text-center text-white font-bold'>MERON</CCardHeader>
                                        <CListGroup flush>
                                            <CListGroupItem>PROFIT: </CListGroupItem>
                                            <CListGroupItem>RATE: {rateMeron}</CListGroupItem>
                                            <CListGroupItem>BET: </CListGroupItem>
                                        </CListGroup>
                                    </CCard>
                                    <CButton color='danger' disabled={betStatus != 2} style={{ width: '100%' }} onClick={() => setWinner('meron')}>WIN</CButton>
                                </CCol>
                                <CCol md={4}>
                                    <CCard color='dark' className='mb-4'>
                                        <CCardHeader className='text-center text-white font-bold'>DRAW</CCardHeader>
                                        <CListGroup flush>
                                            <CListGroupItem>PROFIT: </CListGroupItem>
                                            <CListGroupItem>RATE 1:8 </CListGroupItem>
                                            <CListGroupItem>BET: </CListGroupItem>
                                        </CListGroup>
                                    </CCard>
                                    <CButton color='dark' disabled={betStatus != 2} style={{ width: '100%' }} onClick={() => setWinner('draw')}>DRAW</CButton>
                                </CCol>
                                <CCol md={4}>
                                    <CCard color='primary' className='mb-4'>
                                        <CCardHeader className='text-center text-white font-bold'>WALA</CCardHeader>
                                        <CListGroup flush>
                                            <CListGroupItem>PROFIT: </CListGroupItem>
                                            <CListGroupItem>RATE: {rateWala}</CListGroupItem>
                                            <CListGroupItem>BET: </CListGroupItem>
                                        </CListGroup>
                                    </CCard>
                                    <CButton color='primary' disabled={betStatus != 2} style={{ width: '100%' }} onClick={() => setWinner('wala')}>WIN</CButton>
                                </CCol>
                            </CRow>
                            <CRow className='mt-4'>
                                <CCol md={4}>
                                    <Config party='meron' rate={rateMeron} setRate={setRate} />
                                </CCol>
                                <CCol md={4}>

                                </CCol>
                                <CCol md={4}>
                                    <Config party='wala' rate={rateWala} setRate={setRate} />
                                </CCol>
                            </CRow>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>

        </>
    )
}

export default FightIndex
