import { cilPencil, cilTrash } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CButton, CCallout } from '@coreui/react'
import React from 'react'

export default function MenuItem({item}) {
  return (
    <CCallout color="primary">
      {item.text}
      <CButton size='sm' className='float-end'><CIcon icon={cilPencil} /> Sửa</CButton>
      <CButton size='sm' className='float-end' color='light'><CIcon icon={cilTrash} /> Xoá</CButton>
    </CCallout>
  )
}
