import axiosClient from "../../../api/axiosClient"

const hallApi = {
    get(params){
        return axiosClient.get('/hall', {
            params: params
        })
    },
    detail(){

    },
    create(){

    },
    update(){

    },
    delete(){

    }
}

export default hallApi