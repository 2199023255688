import React from 'react'
import { Route, Routes } from 'react-router-dom'
import DashboardLayout from '../../components/Layout/DashboardLayout'
import AuthMiddleware from '../../components/MiddleRoutes/AuthMiddleware'
import { route } from './route'

export default function MenuFeature() {
    return (<Routes>
        <Route Component={AuthMiddleware}>
          <Route Component={DashboardLayout}>
          {route.map((value, key) => {
            return (
              <Route
                key={key}
                path={value.path}
                Component={value.Component}
              />
            );
          })}
          </Route>
        </Route>
      </Routes>)
}
