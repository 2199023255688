import { cilPlus } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
    CAccordion,
    CAccordionBody,
    CAccordionHeader,
    CAccordionItem,
    CButton,
    CForm,
    CFormInput,
    CInputGroup,
    CInputGroupText,
    CRow
} from "@coreui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import { createMenuValidator } from "../validation/createMenuValidator";

export default function MenuForm({module}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {
      name: "",
      path: "",
      groups: {}
    },
    resolver: yupResolver(createMenuValidator),
  });
  return (
    
          <CForm>
            <CInputGroup className="mb-4">
              <CInputGroupText id="addon-wrapping">Tên menu</CInputGroupText>
              <CFormInput
                placeholder="Tên menu"
                name="moduleName"
                {...register("name")}
              />
              <div
                className="error invalid-feedback"
                style={{ display: "flex" }}
              >
                {errors.name?.message}
              </div>
            </CInputGroup>
            <CInputGroup className="mb-4">
              <CInputGroupText id="addon-wrapping">Đường dẫn</CInputGroupText>
              <CFormInput
                placeholder="Tên menu"
                name="moduleName"
                {...register("name")}
              />
              <div
                className="error invalid-feedback"
                style={{ display: "flex" }}
              >
                {errors.name?.message}
              </div>
            </CInputGroup>
            <CRow className='pt-2'>
                <CButton type='submit' className="px-4">Thêm</CButton>
            </CRow>
          </CForm>
        
  );
}
