import {
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { Suspense } from "react";
import { useForm } from "react-hook-form";
import * as Yup from 'yup';
import moduleApi from "../../api/moduleApi";
import Swal from "sweetalert2";
export default function CreateModal({ action, show, setvisible, module, ...props }) {
  console.log(module)
  const CreateComponent = React.lazy(() =>
    import("./create" + action + "Modal")
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {
      name: '',
      migration: null,
      seeder: null,
      request: null,
      controller: null,
      resource: null
    },
    resolver: yupResolver(Yup.object({
      name : Yup.string().required("Vui lòng điền tên class")
    })),
  });
  const onSubmit    =  async (data)  =>{
    try {
      console.log({action, module, ...data})
        let response = await moduleApi.createElement({action, module, ...data})
        Swal.fire({text: response.message})
    } catch (error) {
        
    }
  }
  const formProps = {
    register,
    handleSubmit,
    errors,
    onSubmit
  }
  return (
    <CModal
      visible={show}
      onClose={() => setvisible(false)}
    >
      <CModalHeader>
        <CModalTitle>Tạo {action} </CModalTitle>
      </CModalHeader>
      <CModalBody>
        <Suspense fallback={<div>Loading...</div>}>
          <CreateComponent {...props} formProps={formProps}/>
        </Suspense>
      </CModalBody>
    </CModal>
  );
}
