import React from 'react'
import { useParams } from 'react-router-dom'
import useModuleDetail from '../hooks/useModuleDetail'

function ModuleDetail() {
    const {id} = useParams()
    const module = useModuleDetail(id)
    return (
        <>
        ID: {JSON.stringify(module)}
        </>
    )
}

export default ModuleDetail
